import parse from 'html-react-parser'
import React, { useState } from 'react'
import { Button, Modal } from '../../../../../../components'
import i18n from '../../../../../../i18n'
import { useTriggeredDeletePropertyTaxData } from '../../../../../../services'
import { updateWalletProfileAdditionalInformationNotification, useContext } from '../../../../../../Context'
import { ReactComponent as Checkmark } from '../../../../../../asset/icon-check.svg'
import { ReactComponent as Warning } from '../../../../../../asset/icon-warning.svg'

interface Props {
  close: () => void
  isOpen: boolean
  getProfile: () => void
}

const RemovePropertyTaxModal = ({ close, isOpen, getProfile }: Props) => {
  const { deletePropertyTaxData, isDeletingPropertyTaxData } = useTriggeredDeletePropertyTaxData()
  const [isLoading, setIsLoading] = useState(false)
  const { dispatch } = useContext()
  const remove = async () => {
    setIsLoading(true)
    const propertyDeletionResponse = await deletePropertyTaxData()
    if (propertyDeletionResponse.response?.status === 200) {
      dispatch(
        updateWalletProfileAdditionalInformationNotification({
          dataTestId: 'success-banner',
          icon: <Checkmark />,
          content: i18n.t('property-info-removed'),
        }),
      )
    } else {
      dispatch(
        updateWalletProfileAdditionalInformationNotification({
          dataTestId: 'banner-error',
          icon: <Warning />,
          content: i18n.t('info-update-failed'),
        }),
      )
    }
    await getProfile()
    setIsLoading(false)
    close()
  }

  return (
    <Modal toggleModal={close} isModalOpen={isOpen} className="wallet-history-key-definition">
      <div data-testid={'remove-property-tax-modal'}>
        <h4 className="title" data-testid={'key-title'}>
          {parse(i18n.t('remove-property-tax-modal-title'))}
        </h4>
        <div className="subtitle" data-testid={'Body'}>
          <p>{parse(i18n.t('remove-property-tax-modal-body'))}</p>
        </div>
        <div className="btn-group">
          <Button dataTestId="Button-secondary" secondary className={'btn-health-card-cancel'} onClick={close} text={i18n.t('go-back')} />
          <Button dataTestId="Button-primary" onClick={remove} className="btn-health-card" text={i18n.t('remove')} isLoading={isDeletingPropertyTaxData || isLoading} />
        </div>
      </div>
    </Modal>
  )
}

export default RemovePropertyTaxModal
