import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import useIdvVerification from '../../hooks/useIdvVerification'
import { getServices } from '../../../../wallet/services/ServiceRegistry'
import useConsent from '../../hooks/useConsent'

type ConsentUrlParams = {
  type: 'property' | 'health_card'
}

const WalletIDVRedirect = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const type = queryParams.get('type') as ConsentUrlParams['type'] | null

  const { storage } = getServices()
  const transactionID = storage.get('cr_token')
  useConsent(transactionID!) // triggers any side effects

  const { getIDVHealthCardLink, getIDVPropertyTaxLink } = useIdvVerification()

  useEffect(() => {
    const redirectIDV = async () => {
      if (type === 'health_card') {
        await getIDVHealthCardLink() // this handles redirect
      } else if (type === 'property') {
        await getIDVPropertyTaxLink() // this handles redirect
      }
    }
    redirectIDV()
    // eslint-disable-next-line
  }, [])

  return null
}

export default WalletIDVRedirect
