import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

import {
  AccountInPersonReadyRouter,
  AccountReadyRouter,
  AddAuthenticatorAppRouter,
  AddAuthenticatorCodeRouter,
  AddEmailAuthRouter,
  AddSMSAuthRouter,
  AfterCreateIdEmailRouter,
  AfterForgotPasswordRouter,
  AfterResetPasswordRouter,
  AfterSignInRouter,
  AfterSMSOTPRouter,
  Change2FAAppCodeRouter,
  Change2FAAppQRRouter,
  Change2FAAppSetupRouter,
  Change2FAEmailRouter,
  Change2FARouter,
  Change2FASmsOtpRouter,
  Change2FASmsRouter,
  ChangeEmailOTPRouter,
  Choose2FARouter,
  CreateIdEmailRouter,
  CreateInPersonRouter,
  ForgotPasswordRouter,
  IdentityVerificationDriverLicenceCardLevel3Router,
  IdentityVerificationHealthCardLevel3Router,
  IdentityVerificationRouter,
  IdentityVerificationVoluntaryIdCardLevel3Router,
  LogoutRouter,
  ResetPasswordRouter,
  SetupAuthenticatorAppRouter,
  SignInRouter,
  TermsOfServiceRouter,
  VerifyIdRouter,
  VerifyPeiIdCallbackRouter,
  VerifyPropertyTaxCallbackRouter,
} from '../router'

import ProtectedRoute, { DefaultRoute, PublicRoute } from '../utils/RouteTypes'

import ROUTES from './common/constants'

import { ProtectedWalletRoute } from '../utils/ProtectedWalletRoute'
import {
  WalletAuthCallback,
  WalletChangeEmail,
  WalletConsentCallback,
  WalletConsentePage,
  WalletConsentHistoryDetailPage,
  WalletConsentHistoryPage,
  WalletConsentSuccessPage,
  WalletIdentityVerificationPage,
  WalletLoginPage,
  WalletProfilePage,
  WalletRSCallback,
  WalletIDVRedirect
} from '../views'
import AccountSetupHelpPage from '../views/Wallet/pages/help/AccountSetupHelpPage'
import GenericErrorPage from '../views/Wallet/pages/error/GenericErrorPage'

const AppRoutes = () => {
  return (
    <Router>
      <Switch>
        {/* Wallet Routes */}
        <PublicRoute exact path={ROUTES.WalletRoute} component={WalletLoginPage} />
        <PublicRoute exact path={ROUTES.WalletWelcomeRoute} component={WalletLoginPage} />
        <PublicRoute exact path={ROUTES.WalletCallback} component={WalletAuthCallback} />
        <PublicRoute exact path={ROUTES.WalletRSCallback} component={WalletRSCallback} />
        <PublicRoute exact path={ROUTES.WalletConsentCallback} component={WalletConsentCallback} />

        <ProtectedWalletRoute exact path={ROUTES.WalletProfile} component={WalletProfilePage} />
        <ProtectedWalletRoute exact path={ROUTES.WalletConsentPage} component={WalletConsentePage} />
        <ProtectedWalletRoute exact path={ROUTES.WalletConsentHistory} component={WalletConsentHistoryPage} />
        <ProtectedWalletRoute exact path={ROUTES.WalletConsentHistoryDetail} component={WalletConsentHistoryDetailPage} />
        <ProtectedWalletRoute exact path={ROUTES.WalletIdentityVerification} component={WalletIdentityVerificationPage} />
        <ProtectedWalletRoute exact path={ROUTES.WalletConsentSuccess} component={WalletConsentSuccessPage} />
        <ProtectedWalletRoute exact path={ROUTES.WalletChangeEmailRoute} component={WalletChangeEmail} />
        <ProtectedWalletRoute exact path={ROUTES.WalletOTPChangeEmailRoute} component={ChangeEmailOTPRouter} />
        <ProtectedWalletRoute exact path={ROUTES.WalletIDVRedirect} component={WalletIDVRedirect} />

        <PublicRoute exact path={ROUTES.LandingPage} component={SignInRouter} />
        <PublicRoute exact path={ROUTES.TermsOfServiceRoute} component={TermsOfServiceRouter} />
        <PublicRoute exact path={ROUTES.CreatePeiIdRoute} component={CreateIdEmailRouter} />
        <PublicRoute exact path={ROUTES.OTPRoute} component={AfterCreateIdEmailRouter} />
        <ProtectedRoute exact path={ROUTES.VerifyPeiIdRoute} component={VerifyIdRouter} />
        <ProtectedRoute exact path={ROUTES.AccountReadyRoute} component={AccountReadyRouter} />

        {/* IN Person */}
        <PublicRoute exact path={ROUTES.CreateInPersonRoute} component={CreateInPersonRouter} />
        <PublicRoute exact path={ROUTES.AccountInPersonReadyRoute} component={AccountInPersonReadyRouter} />

        {/* Log in to MyPEI for the 2nd time (LOA 3) */}
        <PublicRoute path={ROUTES.SigninRoute} exact component={SignInRouter} />
        <PublicRoute path={ROUTES.OTPSignInRoute} exact component={AfterSignInRouter} />
        <PublicRoute path={ROUTES.ForgotPasswordRoute} exact component={ForgotPasswordRouter} />
        <PublicRoute path={ROUTES.ForgotPasswordEmailRoute} exact component={AfterForgotPasswordRouter} />
        <PublicRoute path={ROUTES.ResetPasswordRoute} exact component={ResetPasswordRouter} />
        <PublicRoute path={ROUTES.ResetPasswordSuccessRoute} exact component={AfterResetPasswordRouter} />
        {/* Choose 2FA */}
        <PublicRoute path={ROUTES.Choose2FARoute} exact component={Choose2FARouter} />
        <PublicRoute path={ROUTES.AddEmailAuthRoute} exact component={AddEmailAuthRouter} />
        <PublicRoute path={ROUTES.SMSOTPRoute} exact component={AfterSMSOTPRouter} />
        <PublicRoute path={ROUTES.AddSMSAuthRoute} exact component={AddSMSAuthRouter} />
        {/* Manage Verification Flow */}
        <ProtectedRoute path={ROUTES.IdentityVerificationRoute} exact component={IdentityVerificationRouter} />

        <ProtectedRoute path={ROUTES.IdentityVerificationDriverLicenceCardLevel3Route} exact component={IdentityVerificationDriverLicenceCardLevel3Router} />
        <ProtectedRoute path={ROUTES.IdentityVerificationVoluntaryIdCardLevel3Route} exact component={IdentityVerificationVoluntaryIdCardLevel3Router} />
        <ProtectedRoute path={ROUTES.IdentityVerificationHealthCardLevel3Route} exact component={IdentityVerificationHealthCardLevel3Router} />

        {/* Authenticator 2fa Routes */}
        <PublicRoute path={ROUTES.SetupAuthenticatorAppRoute} exact component={SetupAuthenticatorAppRouter} />
        <PublicRoute path={ROUTES.AddAuthenticatorAppRoute} exact component={AddAuthenticatorAppRouter} />
        <PublicRoute path={ROUTES.AddAuthenticatorCodeRoute} exact component={AddAuthenticatorCodeRouter} />

        {/* Account setup help */}
        <PublicRoute path={ROUTES.AccountSetupHelpRoute} exact component={AccountSetupHelpPage} />

        {/* Error routes */}
        <PublicRoute path={ROUTES.ErrorGenericRoute} exact component={GenericErrorPage} />

        {/* logout */}
        <PublicRoute exact path={ROUTES.LogoutRoute} component={LogoutRouter} />
        {/* For the invalid urls redirect to window.GLOBAL_PATH */}

        {/* Idv Callback */}
        <ProtectedRoute exact path={ROUTES.VerifyPeiIdCallbackRoute} component={VerifyPeiIdCallbackRouter} />
        <ProtectedRoute exact path={ROUTES.VerifyPropertyTaxCallbackRoute} component={VerifyPropertyTaxCallbackRouter} />

        {/* Change 2FA */}
        <ProtectedWalletRoute exact path={ROUTES.Change2FARoute} component={Change2FARouter} />
        <ProtectedWalletRoute exact path={ROUTES.Change2FASmsRoute} component={Change2FASmsRouter} />
        <ProtectedWalletRoute exact path={ROUTES.Change2FASmsOtpRoute} component={Change2FASmsOtpRouter} />
        <ProtectedWalletRoute exact path={ROUTES.Change2FAAppSetupRoute} component={Change2FAAppSetupRouter} />
        <ProtectedWalletRoute exact path={ROUTES.Change2FAAppQRRoute} component={Change2FAAppQRRouter} />
        <ProtectedWalletRoute exact path={ROUTES.Change2FAAppCodeRoute} component={Change2FAAppCodeRouter} />
        <ProtectedWalletRoute exact path={ROUTES.Change2FAEmailRoute} component={Change2FAEmailRouter} />

        <DefaultRoute />
      </Switch>
    </Router>
  )
}

export default AppRoutes
